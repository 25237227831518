import React from "react";
import ListingCardView from "./ListingCard.view";
import { useRouter } from "next/router";
import {
  difficultyColorsMap,
  listingCategoryColorsMap,
} from "./ListingCard.model";
import { convertStringToSlug } from "@/outscal-commons-frontend/utils/stringOperations";
import { isMobile } from "@/hooks/useMobileBreakPoint";

const ListingCard = ({ id, item, customCardData, name }) => {
  const {
    link = "",
    type = "",
    isFeatured = false,
    premiumTagText = "",
    title = "",
    difficulty = "",
    access_marker: accessMarker = "BASIC",
  } = item || {};
  const { isLoggedIn = false } = customCardData || {};
  const router = useRouter();

  const disableClick = (e) => {
    e.preventDefault();
  };

  const handleListingCardClick = (e) => {
    e.preventDefault();
    router.push(link);
  };

  return (
    <ListingCardView
      {...{
        ...item,
        difficulty: {
          text: difficulty,
          ...(difficultyColorsMap[difficulty] || {}),
        },
        scroll_id:
          isLoggedIn && type === "Assessment"
            ? `${item?.scroll_id}_new`
            : item?.scroll_id,
      }}
      {...listingCategoryColorsMap[type]}
      premiumTagText={premiumTagText}
      disableClick={disableClick}
      isProPerk={accessMarker === "PRO"}
      isMobile={isMobile}
      handleListingCardClick={handleListingCardClick}
      analyticsText={`${convertStringToSlug(title)}_${type}_${name}`}
    />
  );
};

export default ListingCard;
