import React from "react";
import {
  CardDetailsWrapper,
  CardImg,
  Root,
  SkillTag,
  UspTag,
  CTAButton,
  PremiumTag,
  ProTagWrapper,
} from "./ListingCard.styles";
import { RowDiv, ColDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import Link from "next/link";
import { Text } from "@/outscal-commons-frontend/Styled/TextStyles";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import ProTag from "@/mvcComponents/Commons/ProTag/ProTag.controller";
import FeatureTags from "./FeatureTags.controller";
import DescriptionText from "./DescriptionText.controller";

const ListingCardView = ({
  image = {},
  difficulty = {},
  title = "",
  description = "",
  uspTag = "",
  analyticsText,
  ctaText = "",
  feeType = "",
  link = "",
  type = "",
  keys = {},
  scroll_id = null,
  premiumTagText = "",
  disableClick,
  handleListingCardClick,
  primaryColor = "monacoBlack",
  isMobile = true,
  secondaryColor = "iconBgGrey",
  isProPerk = false,
}) => {
  return (
    <Root
      id={scroll_id}
      onClick={handleListingCardClick}
      data-analytics={[UIElements.CARD, analyticsText]}
    >
      <CardDetailsWrapper gap="12px" justifyContent="space-between">
        <RowDiv gap="12px" justifyContent="space-between" alignItems="center">
          <RowDiv gap="12px">
            {image?.url && (
              <CardImg
                width={92}
                height={92}
                src={image?.url}
                alt={title}
                quality={20}
              />
            )}
            <ColDiv gap="8px">
              <RowDiv justifyContent="space-between">
                <Link href={link} onClick={disableClick}>
                  <Text fontSize="xl" fontWeight="midBold">
                    {title}
                  </Text>
                </Link>
                {isProPerk && (
                  <ProTagWrapper
                    data-tooltip-id="data-tip"
                    data-tooltip-content={"Unlock with PRO"}
                  >
                    <ProTag />
                  </ProTagWrapper>
                )}
              </RowDiv>
              <RowDiv alignItems="center" gap="8px">
                {type && (
                  <SkillTag
                    primaryColor={"monacoBlack"}
                    secondaryColor={"iconBgGrey"}
                  >
                    {type}
                  </SkillTag>
                )}
                {difficulty?.text && (
                  <SkillTag
                    primaryColor={difficulty?.primaryColor}
                    secondaryColor={difficulty?.secondaryColor}
                  >
                    {difficulty?.icon} {difficulty?.text}
                  </SkillTag>
                )}
              </RowDiv>
              {!isMobile && <FeatureTags tags={keys.keys} />}
            </ColDiv>
          </RowDiv>
          {/* {ctaText && (
            <CTAButton
              fontSize="14px"
              background={primaryColor}
              color="white"
              padding="8px 20px"
              data-analytics={[UIElements.BUTTON, analyticsText]}
            >
              {ctaText}
            </CTAButton>
          )} */}
        </RowDiv>
        {isMobile ? (
          <FeatureTags tags={keys.keys} />
        ) : (
          <DescriptionText description={description} />
        )}
      </CardDetailsWrapper>
      {uspTag && (
        <UspTag primaryColor={primaryColor} secondaryColor={secondaryColor}>
          {uspTag}
        </UspTag>
      )}
      {premiumTagText && (
        <PremiumTag primaryColor={primaryColor} secondaryColor={secondaryColor}>
          {premiumTagText}
        </PremiumTag>
      )}
    </Root>
  );
};

export default ListingCardView;
