import React, { useState } from "react";
import CoursesListingView from "./CoursesListing.view";
import UseComponentVisibility from "@/hooks/useComponentVisibility";
import useAuthActions from "@/store/actions/authAction";

const CoursesListing = ({ data, section_id, heading, showOn }) => {
  const { auth } = useAuthActions();
  const [filters, setFilters] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});

  function sortByPriority(dataArray) {
    return dataArray.sort((a, b) => b.priority - a.priority);
  }

  return (
    <UseComponentVisibility conditions={showOn}>
      <section data-outscal-section className="section-listing">
        <CoursesListingView
          id={section_id}
          heading={heading}
          isLoggedIn={auth?.token}
          data={sortByPriority(data).filter((item) => {
            return Object.keys(appliedFilters).every((key) => {
              if (appliedFilters[key].length === 0) {
                return true;
              }
              const filterValues = appliedFilters[key].map(
                (filter) => filter.label
              );

              if (key === "skills") {
                return item?.skills?.data?.some((skill) =>
                  filterValues.includes(skill?.display_name)
                );
              }

              return filterValues.includes(item[key]);
            });
          })}
          setFilters={setFilters}
          filters={filters}
          setAppliedFilters={setAppliedFilters}
        />
      </section>
    </UseComponentVisibility>
  );
};

export default CoursesListing;
