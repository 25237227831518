export const formatDateString = (dateString) => {
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
    timeZone: "Asia/Kolkata",
  };
  const formattedDate = new Date(dateString).toLocaleDateString(
    "en-IN",
    options
  );
  return formattedDate;
};

export const convertStringToSlug = (label) => {
  return label
    .toLowerCase()
    .replace(/\s+/g, "-") // replace spaces with hyphen
    .replace(/[^\w-]+/g, "-") // replace all non-word characters except hyphen
    .replace(/\-\-+/g, "-") // replace multiple hyphen with a single hyphen
    .replace(/^-+/, "") // remove hyphen from start
    .replace(/-+$/, ""); // remove last hyphen
};

export const convertSlugToString = (input, separator = "-") => {
  return input
    ?.split(separator)
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(" ");
};

export const transformArrayToString = (arr) => {
  return arr ? arr.join(", ") : "";
};

export const transformStringToArray = (str) => {
  let formatString = str?.trim()?.replace(/,+$/, "");
  return formatString ? formatString.split(",").map((item) => item.trim()) : [];
};

export const transformStringArrayToFiltersFormat = (inputArray = []) => {
  const transformedArray = inputArray?.map((item) => {
    const parts = item.split("-");
    const id = parts.join("-");
    const label = parts
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return { id, label };
  });

  return transformedArray || [];
};

export const calculateStringSimilarity = (optionLabel, inputText) => {
  return optionLabel.length === 0 ? 1 : inputText.length / optionLabel.length;
};

export const getSimilaritySortedOptions = (searchTerm, options, value) => {
  const sortedOptions = options
    .filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .map((option) => ({
      ...option,
      label: option.label,
      id: option.id,
    }));

  return sortedOptions;
};

export const sortOptionsWithInputValueOnTop = (
  sortedOptions,
  inputValue,
  pageSize
) => {
  // const sortedOptionsCopy = [...sortedOptions];
  // const sortedOptionsWithInputValue = [];

  // inputValue.forEach((item) => {
  //   const index = sortedOptionsCopy.findIndex(
  //     (option) => option.id === item.id
  //   );
  //   if (index !== -1) {
  //     sortedOptionsWithInputValue.push(sortedOptionsCopy[index]);
  //     sortedOptionsCopy.splice(index, 1);
  //   }
  // });

  // return [...inputValue, ...sortedOptions.slice(0, pageSize)];
  return [...sortedOptions.slice(0, pageSize)];
};

export const moveSelectedValuesToTop = (list, values) => {
  list.sort((a, b) => {
    if (
      values.some((obj) => obj.id === a.id) &&
      !values.some((obj) => obj.id === b.id)
    ) {
      return -1;
    } else if (
      !values.some((obj) => obj.id === a.id) &&
      values.some((obj) => obj.id === b.id)
    ) {
      return 1;
    } else {
      return 0;
    }
  });

  return list;
};

export const evaluateTernaryCondition = (
  conditionString = "",
  context = {}
) => {
  if (!conditionString) {
    return "";
  }

  const match = conditionString?.match(
    /^\s*([a-zA-Z_$][0-9a-zA-Z_$]*)\s*\?\s*"?(.*?)"?\s*:\s*"?(.*?)"?\s*$/
  );

  if (!match) {
    return conditionString;
  }

  const [, variableName, trueResult, falseResult] = match;
  const condition = context[variableName];
  return condition ? trueResult : falseResult;
};
