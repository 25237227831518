import React from "react";
import { Text } from "@/outscal-commons-frontend/Styled/TextStyles";

const DescriptionText = ({ description }) =>
  description ? (
    <Text fontSize="sm" color="textDarkGrey">
      {description}
    </Text>
  ) : null;

export default DescriptionText;
