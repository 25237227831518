import React from "react";
import { SkillTag } from "./ListingCard.styles";
import { RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";

const FeatureTags = ({ tags, primaryColor, secondaryColor }) =>
  tags ? (
    <RowDiv wrap={true} gap="10px" alignItems="center">
      {tags.map((item) => (
        <SkillTag
          key={item}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        >
          {item}
        </SkillTag>
      ))}
    </RowDiv>
  ) : null;

export default FeatureTags;
