import { MdOutlineSignalCellularAlt2Bar } from "react-icons/md";
import { MdOutlineSignalCellularAlt } from "react-icons/md";
import { FaSignal } from "react-icons/fa";

export const listingCategoryColorsMap = {
  Specialization: { primaryColor: "textGreen", secondaryColor: "lightBgGreen" },
  Assessment: { primaryColor: "darkMud", secondaryColor: "seoLightBgYellow" },
  Scholarship: { primaryColor: "darkPurple", secondaryColor: "bgLightPurple" },
  Project: { primaryColor: "darkCyanBlue", secondaryColor: "bgLightBlue" },
};

export const difficultyColorsMap = {
  Beginner: {
    primaryColor: "textGreen",
    secondaryColor: "lightBgGreen",
    icon: <MdOutlineSignalCellularAlt2Bar />,
  },
  Intermediate: {
    primaryColor: "darkMud",
    secondaryColor: "seoLightBgYellow",
    icon: <MdOutlineSignalCellularAlt />,
  },
  Advanced: {
    primaryColor: "deepRed",
    secondaryColor: "bgLightRed",
    icon: <FaSignal />,
  },
};
