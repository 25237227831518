import styled from "styled-components";
import {
  RowDiv,
  Div,
  ColDiv,
} from "@/outscal-commons-frontend/Styled/LayoutUtils";
import Image from "next/image";
import { Button } from "@/outscal-commons-frontend/Styled/Buttons";

export const Root = styled(Div)`
  overflow: hidden;
  background-color: ${(p) => p.theme.colors.white};
  display: flex;
  flex-direction: column;
  border-radius: ${(p) => p.theme.borderRadius.xs};
  transition: all 0.3s linear;
  position: relative;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  &:hover {
    box-shadow: "0 14px 40px rgba(30, 10, 58, 0.1)" 0px 2px 8px 0px;
    scale: 1.02;
  }
`;

export const CardImg = styled(Image)`
  border-radius: ${(p) => p.theme.borderRadius.xxs};
  object-fit: cover;
  @media (min-width: 500px) {
    width: 96px;
    height: 96px;
  }
`;

export const SkillTag = styled(RowDiv)`
  align-items: center;
  gap: 6px;
  color: ${(p) => p.theme.colors[p.primaryColor || "darkPurple"]};
  background-color: ${(p) =>
    p.theme.colors[p.secondaryColor || "bgLightPurple"]};
  font-size: ${(p) => p.theme.fontSize.xs};
  padding: ${(p) => p.padding || "5px 10px"};
  border-radius: ${(p) => p.theme.borderRadius.xxs};
  font-weight: ${(p) => p.theme.fontWeight.medium};
`;

export const CardDetailsWrapper = styled(ColDiv)`
  padding: 16px;
  @media (max-width: 500px) {
    padding: 12px;
  }
`;

export const UspTag = styled(Div)`
  position: relative;
  border-radius: 0 0 ${(p) => p.theme.borderRadius.xs}
    ${(p) => p.theme.borderRadius.xs};
  padding: 6px 16px 8px 16px;
  font-size: ${(p) => p.theme.fontSize.sm};
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  background-color: ${(p) => p.theme.colors[p.secondaryColor]};
  color: ${(p) => p.theme.colors[p.primaryColor]};

  @media (max-width: 500px) {
    text-align: center;
  }
`;

export const ProTagWrapper = styled(Div)`
  @media (min-width: 500px) {
    position: absolute;
    top: 8px;
    right: 16px;
  }
`;

export const PremiumTag = styled(Div)`
  position: absolute;
  top: 18px;
  left: -44px;
  z-index: 1;
  width: 150px;
  padding: 4px 0;
  transform: rotate(-45deg);
  font-size: ${(p) => p.theme.fontSize.sm};
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  background-color: ${(p) => p.theme.colors.red};
  color: ${(p) => p.theme.colors.white};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CTAButton = styled(Button)`
  height: fit-content;
  padding: 10px 24px;
  @media (max-width: 500px) {
    display: none;
  }
  &:hover {
    color: ${(p) => p.theme.colors.white};
  }
  ${(p) =>
    p.highLight &&
    css`
      border: none;
      background-color: ${(p) => p.theme.colors.canvaPurple};
      color: ${(p) => p.theme.colors.white};

      &:hover {
        color: ${(p) => p.theme.colors.white};
        background-color: ${(p) => p.theme.colors.canvaPurple};
      }
    `}
`;
